@font-face {
  font-family: "SimpliSans";
  src: local("SimpliSans"), url(./fonts/SimpliSans-Regular.woff) format("woff");
}
@font-face {
  font-family: "SimpliSans Bold";
  src: local("SimpliSans"), url(./fonts/SimpliSans-Bold.woff) format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "ArizonaSans";
  src: local("ArizonaSans"),
    url(./fonts/ABCArizonaSans-Regular.woff) format("woff");
}
@font-face {
  font-family: "ArizonaSans Bold";
  src: local("ArizonaSans"),
    url(./fonts/ABCArizonaSans-Bold.woff) format("woff");
  font-weight: bold;
}
#QSIFeedbackButton-btn {
  display: none;
}
.App {
  max-width: 1440px;
  margin: 0 auto;
}
.App-header {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}