.pcs_responsive_modal {
  background: #fff;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  width: 50vw;
  height: 60vh;
  padding-left: 3rem;
  position: absolute;
}

@media (max-width: 820px) {
  .pcs_responsive_modal {
    width: calc(100vw - 6rem) !important;
    height: calc(100vh - 6rem) !important;
  }
}
